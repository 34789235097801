* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
img,
svg {
  display: block;
  width: 100%;
  height: 100%;
}
svg {
  fill: currentColor;
  stroke: currentColor;
}
li {
  margin: 6px 0;
}
.gif {
  width: 138px;
  height: 19px;
}
.jpeg {
  width: 152px;
  height: 23px;
}
.jpg {
  width: 139px;
  height: 23px;
}
.png {
  width: 151px;
  height: 19px;
}
.svg-a {
  width: 168px;
  height: 17px;
  color: red;
}
.svg-b {
  width: 168px;
  height: 17px;
  color: blue;
}
.js-target {
  opacity: 0;
  transition: opacity 300ms;
  &.is-active {
    opacity: 1;
  }
}
