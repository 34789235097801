@import './sample';

// Required
.svg-sprite {
  display: none;
}

.wrapper{
  display: block;
  width: 100%;
  height: 100vh;
  position: relative;
  h1{
    position: absolute;
    left: 50%;
    top: 20%;
    font-size: 40px;
    transform:translateX(-50%);
    z-index: 2;
    font-family: 'Comfortaa', cursive;
    text-shadow:2px 2px 0 #FFF, -2px -2px 0 #FFF,
    -2px 2px 0 #FFF, 2px -2px 0 #FFF,
    0px 2px 0 #FFF,  0 -2px 0 #FFF;
  }
}

.map{
  display: block;
  width: 100%;
  height: 100vh;
  background: #000;
}